.calendar-note[data-v-feec6ce1] {
  display: inline-block;
  width: 15rem;
  height: 8rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--color-bg-note);
  padding: 0.6rem;
  border-radius: var(--radius);
}
.calendar-note-top[data-v-feec6ce1] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.calendar-note-author[data-v-feec6ce1] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
}
.calendar-note-avatar[data-v-feec6ce1] {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 0.6rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-primary);
  display: flex;
  justify-content: center;
  align-self: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--radius-max);
}
.calendar-note-text[data-v-feec6ce1] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  overflow-wrap: break-word;
  margin-top: 0.3rem;
  overflow: hidden;
  text-overflow: clip;
  height: 3.5rem;
  width: 100%;
}
.calendar-note-textarea[data-v-feec6ce1] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  overflow-wrap: break-word;
  margin-top: 0.3rem;
  width: 100%;
  height: 3.5rem;
  background-color: var(--color-bg-note);
  overflow-y: hidden;
  resize: none;
  border: none;
}
.calendar-note-date[data-v-feec6ce1] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  text-align: end;
  color: var(--color-text-secondary);
}
.calendar-note-delete[data-v-feec6ce1] {
  padding: 0;
}
.calendar-note-delete[data-v-feec6ce1]:hover {
  background-color: transparent;
}
.calendar-note-delete-icon[data-v-feec6ce1] {
  color: var(--color-icon);
}
.calendar-note-delete:hover .calendar-note-delete-icon[data-v-feec6ce1] {
  color: var(--color-text-warning);
}