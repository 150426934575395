.week-replacement-suggestions[data-v-676b2b24] {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  width: 75rem;
  padding: 1rem 0;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}
.week[data-v-676b2b24] {
  width: 100%;
  height: 100%;
}
.week-day-name[data-v-676b2b24] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  text-align: center;
  width: 100%;
}
.week-day-cars[data-v-676b2b24] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
}
.week-day-car[data-v-676b2b24] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--color-border-primary);
  padding-inline: 6px;
  height: 1.6rem;
  gap: 0.2rem;
  border-radius: var(--radius);
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}
.week-day-car.-replacement[data-v-676b2b24] {
  height: 9.6rem;
}
.week-day-car.-no-hover[data-v-676b2b24]:hover {
  cursor: auto;
  background-color: transparent;
}
.car-details[data-v-676b2b24] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.week-day-empty[data-v-676b2b24] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  text-align: center;
}
.week-replacements-suggestions-buttons[data-v-676b2b24] {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 1rem;
}
.loading-data[data-v-676b2b24] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loading-icon[data-v-676b2b24] {
  color: var(--color-text-primary);
  font-size: 2rem;
}
.list-enter-active[data-v-676b2b24],
.list-leave-active[data-v-676b2b24] {
  transition: all var(--p-transition-duration) ease;
}
.list-move[data-v-676b2b24] {
  transition: transform var(--p-transition-duration) ease;
}
.list-enter-from[data-v-676b2b24],
.list-leave-to[data-v-676b2b24] {
  opacity: 0;
  transform: scale(0.8);
}
.list-enter-to[data-v-676b2b24],
.list-leave-from[data-v-676b2b24] {
  opacity: 1;
  transform: scale(1);
}
.list-leave-active[data-v-676b2b24] {
  position: absolute;
  top: 0;
}