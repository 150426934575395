.calendar-week-header[data-v-a5f953cf] {
  display: grid;
  flex-direction: row;
  align-items: start;
  grid-template-columns: 22rem 2fr 2fr 1fr;
  gap: 0.5rem;
  width: 100%;
}
.calendar-week-toolbar[data-v-a5f953cf] {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
  height: 2rem;
  gap: 1rem;
}
.calendar-week-details[data-v-a5f953cf] {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 3.5rem;
  color: var(--color-text-primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.calendar-week-replacement-count[data-v-a5f953cf] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.calendar-week-tools[data-v-a5f953cf] {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.calendar-week-tools-icon[data-v-a5f953cf] {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-primary);
}
.calendar-week-tools-button[data-v-a5f953cf] {
  color: var(--color-icon);
}
.calendar-week-tools-button[data-v-a5f953cf]:hover {
  color: var(--color-icon-focus);
}