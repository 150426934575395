@keyframes highlightBorderNoConflict-6ffe47ce {
0% {
    border-color: var(--color-border-primary);
}
25% {
    border-color: var(--color-text-submit);
}
75% {
    border-color: var(--color-text-submit);
}
100% {
    border-color: var(--color-border-primary);
}
}
@keyframes highlightBorderConflict-6ffe47ce {
0% {
    border-color: var(--color-border-primary);
}
100% {
    border-color: var(--color-text-warning);
}
}
.replacement[data-v-6ffe47ce] {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.4rem;
  border: 1px dashed var(--color-border-primary);
  border-radius: var(--radius);
  width: 100%;
  transition: background-color var(--p-transition-duration) ease-in-out;
}
.replacement[data-v-6ffe47ce]:hover {
  background-color: var(--content-bg--color-focus);
}
.replacement[data-v-6ffe47ce]:has(.delete-replacement-button:hover) {
  background-color: transparent;
}
.replacement.-conflict[data-v-6ffe47ce] {
  border-color: var(--color-text-warning);
  animation: highlightBorderConflict-6ffe47ce 1s ease-in-out;
}
.replacement[data-v-6ffe47ce]:not(-conflict) {
  animation: highlightBorderNoConflict-6ffe47ce 1s ease-in-out;
}
.replacement-details[data-v-6ffe47ce] {
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
  padding-top: 0.2rem;
}
.replacement-car[data-v-6ffe47ce] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  width: 100%;
  margin-top: -0.4rem;
}
.replacement-car-text[data-v-6ffe47ce] {
  max-width: 10rem;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: block;
  text-align: left;
}
.replacement-dates[data-v-6ffe47ce] {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
}
.replacement-date[data-v-6ffe47ce] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-submit);
}
.delete-replacement-container[data-v-6ffe47ce] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.delete-replacement-button[data-v-6ffe47ce] {
  color: var(--color-icon);
}
.delete-replacement-button[data-v-6ffe47ce]:hover {
  color: var(--color-text-warning);
}