.calendar-week[data-v-9ab12209] {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: var(--radius);
  padding: 0 1rem;
  height: auto;
  margin: 1rem 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.calendar-week.-visible[data-v-9ab12209] {
  opacity: 1;
}
.calendar-week.-padding-right[data-v-9ab12209] {
  padding-right: 1.7rem;
}
.calendar-week-notes[data-v-9ab12209] {
  position: relative;
  width: 100%;
}
.calendar-week-days[data-v-9ab12209] {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  width: 100%;
  min-height: 20rem;
}
.sentinel[data-v-9ab12209] {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 10000px;
}
.sentinel.-bottom[data-v-9ab12209] {
  align-items: flex-start;
}
.loading-icon[data-v-9ab12209] {
  font-size: 2rem;
  color: var(--color-text-primary);
}
.list-enter-active[data-v-9ab12209],
.list-leave-active[data-v-9ab12209] {
  transition: all var(--p-transition-duration) ease;
}
.list-move[data-v-9ab12209] {
  transition: transform var(--p-transition-duration) ease var(--p-transition-duration);
}
.list-enter-from[data-v-9ab12209],
.list-leave-to[data-v-9ab12209] {
  opacity: 0;
  transform: scale(0.8);
}
.list-enter-to[data-v-9ab12209],
.list-leave-from[data-v-9ab12209] {
  opacity: 1;
  transform: scale(1);
}
.list-leave-active[data-v-9ab12209] {
  position: absolute;
  top: 0;
}