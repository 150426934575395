.replacement-header[data-v-0c392b5d] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
}
.replacement-header span[data-v-0c392b5d] {
  color: var(--color-text-secondary);
}
.replacement-empty[data-v-0c392b5d] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.replacement-empty-link[data-v-0c392b5d] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  background-color: var(--color-button-bg-submit);
  color: var(--color-text-quaternary);
  padding: 0.4rem 0.8rem;
  border-radius: var(--radius);
  cursor: pointer;
  z-index: 2;
  opacity: 1;
  margin-top: 0.4rem;
  width: fit-content;
}
.replacement-empty-link[data-v-0c392b5d]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
  color: var(--color-text-quaternary);
  opacity: 1;
}
.replacement-empty-icon[data-v-0c392b5d] {
  width: 1.2rem;
  height: 1.2rem;
}